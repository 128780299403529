import React from "react";
// Customizable Area Start
import {
  Box,
  Typography,
  styled,
  Backdrop,
  CircularProgress,
  Divider,
  IconButton,
  Card,
  CardContent
} from "@material-ui/core";
import ProductPreviewController, { Props, Section, configJSON } from "./ProductPreviewScreenController.web";
import { GooglePlayIcon, AppStore, SideArrow, socialTwitter, AddIcon, socialFacebook, socialInstagram, CloseIcon, MenuIcon, searchIcon, socialYoutube, Linkdin, WhatsApp, CallIcon, EmailIcon, closeBold, quote, rightIcon, leftIcon, facebookLogo, instagramLogo, twitterLogo, youtubLogo } from "./assets";
import withLoader from "../../ss-cms-common-components/src/HOC/withBrandingSpinner.web";
import "./styles.css";
import { withDialog } from "../../ss-cms-common-components/src/Dialog/withDialog";
import withHeaderBar from "../../ss-cms-common-components/src/HOC/withHeadeActionBar.Web";
import { ServiceImage } from "../../utilities/src/models/ServiceImage";
import Currency from "../../utilities/src/components/Currency.web";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { StaffData } from "../../dashboard/src/StylistCarouselController.web";

const primaryColor = getComputedStyle(document.documentElement).getPropertyValue('--website-builder-primary-color').trim();

var settings = {
  dots: false,
  infinite: true,
  speed: 500,
  // slidesToShow: 3,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 3000,
};
// Customizable Area End


class ProductPreviewScreen extends ProductPreviewController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    // Customizable Area Start
    if(this.props.statesData.expandedSection != this.props.selectedSection){
      this.scrollToTarget()
    }
    
    const { locationDetails } = this.state;
    const selectedColor  = this.props.statesData.selectedColor;
    const selectedFonts  = this.props.statesData.selectedFonts;
    // Customizable Area End
    return (
      // Customizable Area Start
      <> 
        {
          this.props.statesData.isLoading ?
            <Backdrop style={{ zIndex: 9999 }} open={this.props.statesData.isLoading} >
              <div
                style={{
                  position: "absolute",
                  left: "50%",
                  top: "50%",
                  transform: "translate(-50%, -50%)",
                }}
              >
                <CircularProgress
                  size="5rem"
                  color="primary"
                  data-testid="page-loader"
                />
              </div>
            </Backdrop> :
            <ProductPreviewBox id="myScrollableDiv">
              {this.props?.updateData?.header?.isMobileMenuOpen ? (
                <MobileMenuScreen>
                  <MobileMenuPreviewHeader>
                    <Box />
                    <HeaderCloseIcon src={closeBold} />
                  </MobileMenuPreviewHeader>
                  {
                    this.props?.statesData?.navigationItems?.nevigationItem1 ?
                    <NavigationSettingsHeader>
                      <HeaderNavigation style={{color: selectedColor.secondary, fontFamily: selectedFonts.body}}>{this.props.statesData?.navigationItems.nevigationItem1}</HeaderNavigation>
                      <MobileMenuVectorIcon src={SideArrow} />
                    </NavigationSettingsHeader> :
                    <NavigationSettingsHeader>
                      <HeaderNavigation style={{color: selectedColor.secondary, fontFamily: selectedFonts.body}}>{configJSON.services}</HeaderNavigation>
                      <MobileMenuVectorIcon src={SideArrow} />
                    </NavigationSettingsHeader>
                  }
                  {
                    this.props?.statesData?.navigationItems?.nevigationItem2 ?
                    <NavigationSettingsHeader>
                      <HeaderNavigation style={{color: selectedColor.secondary, fontFamily: selectedFonts.body}}>{this.props.statesData?.navigationItems.nevigationItem2}</HeaderNavigation>
                      <MobileMenuVectorIcon src={SideArrow} />
                    </NavigationSettingsHeader> :
                    <NavigationSettingsHeader>
                      <HeaderNavigation style={{color: selectedColor.secondary, fontFamily: selectedFonts.body}}>{configJSON.serviceProviders}</HeaderNavigation>
                      <MobileMenuVectorIcon src={SideArrow} />
                    </NavigationSettingsHeader>
                  }
                  {
                    this.props?.statesData?.navigationItems?.nevigationItem3 ?
                    <NavigationSettingsHeader>
                      <HeaderNavigation style={{color: selectedColor.secondary, fontFamily: selectedFonts.body}}>{this.props.statesData?.navigationItems.nevigationItem3}</HeaderNavigation>
                      <MobileMenuVectorIcon src={SideArrow} />
                    </NavigationSettingsHeader> :
                    <NavigationSettingsHeader>
                      <HeaderNavigation style={{color: selectedColor.secondary, fontFamily: selectedFonts.body}}>{configJSON.gallery}</HeaderNavigation>
                      <MobileMenuVectorIcon src={SideArrow} />
                    </NavigationSettingsHeader>
                  }
                  {
                    <ManageBookingBtn style={{backgroundColor: selectedColor.primary}}>
                      {configJSON.manageBooking}
                    </ManageBookingBtn>
                  }
                </MobileMenuScreen>
              ) : (
                <MobilePreview>
                  <MobilePreviewHeader id="headerUnique">
                    <HeaderMenuIcon src={MenuIcon} />
                    <HeaderIconAndNameHolder title={this.props.statesData?.storeName}>
                    <>
                    {!this.props?.updateData?.header?.isLogoUploaded && !this.props.statesData.removeLogo && !this.props?.updateData?.header?.isStoreNameEnabled &&
                        <AddLogoBox>
                            <AddLogoPlusIcon src={AddIcon} />
                            <AddLogoText>Add Logo</AddLogoText>
                        </AddLogoBox>
                    }
                    {(this.props?.updateData?.header?.isLogoUploaded && !this.props.statesData.removeLogo) &&
                       <img src={this.props?.updateData?.header?.selectedLogo} alt="Logo" height="27px" style={{ maxWidth: "107px" }} />
                      }
                    {(this.props?.updateData?.header?.isLogoUploaded ? this.props?.updateData?.header?.isStoreNameEnabled : this.props.statesData?.storeName) &&
                     <Box style={{
                      color: `${this.props.statesData.textLogoMetaData.colour}`, 
                      fontFamily: `${this.props.statesData.textLogoMetaData.fontType}`,
                      fontSize: 17,
                      fontWeight: 500,
                      letterSpacing: "-0.02em",
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                      textAlign: 'center',
                      width: '100%',
                      maxWidth: "107px",
                      marginLeft: "5px"
                      }}> {this.props.statesData?.storeName}</Box>}
                    </>
                    </HeaderIconAndNameHolder>
                    <div style={{ display: 'flex', alignItems: 'center', gap: '16px' }}>
                    <HeaderSearchIcon src={searchIcon} />
                    </div>
                  </MobilePreviewHeader>

                  {this.props.statesData.SectionData?.map((section: any, index: any) => {
                    const {metadata, bannerBackgroundColour, mobileBannerMetadata} = section
                    if (section?.type?.toLowerCase() === "banner" && !section?.is_deleted) {
                      return section?.desktopBannerUpload && section?.desktopBannerUpload.length > 0 ? (
                        <BannerContainer style={{ backgroundColor: bannerBackgroundColour }} key={section?.id} id={`section${index}Unique`}>
                          <Box>
                            <BannerText
                              style={{
                                fontFamily: mobileBannerMetadata.font_family,
                                color: mobileBannerMetadata.font_colour,
                                fontSize: `${(mobileBannerMetadata.font_size) / 1.3}px`,
                                textAlign: mobileBannerMetadata.text_alignment,
                                lineHeight: "26.6px",
                                padding: "0px 30px"
                              }}
                            >{section?.mobileBannerMetadata.header_text}</BannerText>
                          </Box>
                          <BannerImageContainer>
                            {(src: string) =>
                              <img
                                src={section?.desktopBannerUpload}
                                style={webStyle.banner}
                                alt="image loading"
                              />
                            }
                          </BannerImageContainer>
                        </BannerContainer>
                      ) : (
                        <AddBannerSection>
                          <InnerAddBannerBox>
                            <AddIconBox>
                              <AddBannerPlusIcon src={AddIcon} />
                            </AddIconBox>
                            <AddBannerText>Add Banner</AddBannerText>
                            <AddBannerSizeText>Max 20MB</AddBannerSizeText>
                          </InnerAddBannerBox>
                        </AddBannerSection>
                      );
                    } else if (section?.type?.toLowerCase() === "staff_display" && !section?.is_deleted) {
                      return (
                        <Box key={section?.id} id={`section${index}Unique`}>
                          <Box style={webStyle.bodyContainer}>
                            <Box style={{ ...webStyle.headerTextField, fontFamily: selectedFonts.header }} >{section?.sectionName}</Box>

                            <SliderContainer>
                              <Slider {...settings} slidesToShow={section?.metadata?.staff.filter((item: StaffData) => item.staffAttrs.id).length > 3 ? 3 : section?.metadata?.staff.filter((item: StaffData) => item.staffAttrs.id).length}>
                                {section?.metadata?.staff?.filter((item: StaffData) => item.staffAttrs.id).map((stylist: StaffData, index: number) => (
                                  <Box key={index} style={webStyle.carouselContainer}>
                                    <img src={stylist?.staffAttrs?.attributes?.image} style={webStyle.textContainer} />
                                    <Box style={webStyle.overlay}>
                                      <Typography style={{...webStyle.staffName, fontFamily: selectedFonts.body}}>{stylist?.staffAttrs?.attributes?.full_name}</Typography>
                                      <Typography style={{...webStyle.designation, fontFamily: selectedFonts.body}}>{stylist?.staffAttrs?.attributes?.designation}</Typography>
                                    </Box>
                                  </Box>
                                ))}
                              </Slider>
                            </SliderContainer>

                            <Box style={{ display: "flex", width: "100%", justifyContent: "center" }}>
                              <ViewAllBtn style={{ backgroundColor: selectedColor.primarySecond, color: selectedColor.primary, fontFamily: selectedFonts.body }}>
                                {configJSON.viewAllText}
                              </ViewAllBtn>
                            </Box>
                          </Box>
                        </Box>
                      );
                    } else if (section?.type?.toLowerCase() === "service_display" && !section.is_deleted && this.props?.statesData?.servicesData?.length) {
                      const selectedServices = section?.metadata?.services?.filter((service: {selected: string}) => service.selected !== "Select");
                      return (
                        <Box key={section?.id} id={`section${index}Unique`}>
                          <HeadingContainer>
                            <SectionHeading style={{color: selectedColor.secondary, fontFamily: selectedFonts.header, margin: "0px" }}>{section?.sectionName}</SectionHeading>
                          </HeadingContainer>
                          <MainServiceBox>
                            <Box className={"bodySection"}>
                              {selectedServices && selectedServices?.filter((item: Section) => item.serviceAttrs.id).map((service: Section, curElem: number) => (
                                <Card elevation={0} key={curElem} className="serviceCard">
                                  <CardContent className={"cardContent"}>
                                    <Box className={"imageBox"}>
                                      <img src={service?.serviceAttrs?.attributes?.images && service?.serviceAttrs?.attributes?.images[0]?.url}
                                        alt={service?.serviceAttrs?.attributes?.title}
                                        width="100%" height="100%" style={webStyle.image} />
                                      <Box className={"timeContainer"}>
                                        <Box className={"duration"}>{service?.serviceAttrs?.attributes?.duration}</Box>
                                        <Box className={"minutes"}>Mins</Box>
                                      </Box>
                                    </Box>
                                    <Box className={"cardFooterConatiner"}>
                                      <Box>
                                        <Typography className="cardDesc" style={{ color: selectedColor.secondary, fontFamily: selectedFonts.body }}>{service?.serviceAttrs?.attributes?.title?.substring(0, 20)}</Typography>
                                        <Box className={"priceBox"} style={{color: selectedColor.secondary, fontFamily: selectedFonts.body}}>
                                          <Typography variant="body2" className={"serviceBrief"} style={{color: selectedColor.secondary, fontFamily: selectedFonts.body }}>
                                            <Currency
                                              text={`${service?.serviceAttrs?.attributes?.discount ? service?.serviceAttrs?.attributes?.discount?.toFixed(2) : ""} `}
                                              disountPrice={`${service?.serviceAttrs?.attributes?.actual_price ? service?.serviceAttrs?.attributes?.actual_price?.toFixed(2) : ""}`}
                                              time={``} hasDiscount={service?.serviceAttrs?.attributes?.discount_option}
                                              fontColor={selectedColor.secondary}
                                              fontFamily={selectedFonts.body}
                                              />
                                          </Typography>
                                        </Box>
                                      </Box>
                                      <Box className={"bookNowButton"} style={{ backgroundColor: selectedColor.primary, fontFamily: selectedFonts.body }}>
                                        {configJSON.textBookNow}
                                      </Box>
                                    </Box>
                                  </CardContent>
                                </Card>
                              ))}

                              <Box style={{ display: "flex", width: "100%", justifyContent: "center" }}>
                                  <ViewAllBtn style={{ backgroundColor: selectedColor.primarySecond, color: selectedColor.primary, fontFamily: selectedFonts.body }}>
                                    {configJSON.viewAllText}
                                  </ViewAllBtn>
                              </Box>
                            </Box>
                          </MainServiceBox>
                        </Box>
                      );
                    } else if (section?.type?.toLowerCase() === "testimonial" && !section?.is_deleted && metadata?.isTestimonial) {
                      return (
                        <MainTestimonialBox key={section?.id} id={`section${index}Unique`}>
                          <Typography align="center" variant="h6" className="sectionName" style={{color: selectedColor.secondary, fontFamily: selectedFonts.header }}>
                            {section?.sectionName}
                          </Typography>
                          <Box className="cardBox">
                            <Box className="card">
                              <Box className="cardHead">
                                <Typography className="testominialName" style={{fontFamily: selectedFonts.body, color: selectedColor.secondary}}>{configJSON.customerName}</Typography>
                                <img src={quote} className="quoteIcon" />
                              </Box>
                              <Box className="cardContent">
                                <Typography className="testiminialtext" style={{fontFamily: selectedFonts.body, color: selectedColor.secondarySecond}}>
                                  {configJSON.testimonilaText}
                                </Typography>
                              </Box>
                            </Box>
                          </Box>
                          <Box className="btnBox">
                            <IconButton className="iconBtn">
                              <img src={leftIcon} className="slideBtn" />
                            </IconButton>
                            <IconButton className="iconBtn">
                              <img src={rightIcon} className="slideBtn" />
                            </IconButton>
                          </Box>
                        </MainTestimonialBox>
                      );
                    } else if (section?.type?.toLowerCase() === "location" && !section?.is_deleted && metadata?.isLocation) {
                      return (
                        <LocationContainer key={section?.id} id={`section${index}Unique`}>
                          <LocationTitleBox>
                            <LocationTitle style={{color: selectedColor.secondary, fontFamily: selectedFonts.header }}>{section?.sectionName}</LocationTitle>
                          </LocationTitleBox>
                              {locationDetails &&
                                  <LocationWrapper>
                                      <LocationMapBox>
                                              <img
                                                  width={"100%"}
                                                  height={"400px"}
                                                  src={(locationDetails?.location?.image as ServiceImage)?.small_url ? (locationDetails?.location?.image as ServiceImage)?.small_url : (locationDetails?.location.image as ServiceImage)?.url}
                                                  alt="image loading"
                                                  style={{objectFit: "cover"}}
                                              />
                                          </LocationMapBox>
                                          <LocationCard>
                                          <Box>
                                              <AddressTitle style={{fontFamily: selectedFonts.body}}>{configJSON.address}</AddressTitle>
                                              <AddressBody style={{fontFamily: selectedFonts.body}}>{`${locationDetails?.addressLine1},${locationDetails?.addressLine2},${locationDetails?.city},${locationDetails?.country}`}</AddressBody>
                                          </Box>
                                          <Devider/>
                                          <Box>
                                          <AddressTitle style={{fontFamily: selectedFonts.body}}>{configJSON.openingTime}</AddressTitle>
                                          {locationDetails?.shopAvailability?.map(
                                                      ({ weekDay, openingTime, closingTime }, index) => (
                                                          <OpeneingBox key={index}>
                                                              <AddressBody style={{fontFamily: selectedFonts.body}}>
                                                                  {weekDay}
                                                              </AddressBody>
                                                              <AddressBody style={{fontFamily: selectedFonts.body}}>
                                                                  {openingTime} - {closingTime}
                                                              </AddressBody>
                                                          </OpeneingBox>
                                                      )
                                                  )}
                                          </Box>
                                          </LocationCard>

                                  </LocationWrapper>
                              }
                        </LocationContainer>
                      );
                  }     
                  })}

                  <FooterSectionBox id="footerUnique">
                     <FooterHeadingText style={{fontFamily: selectedFonts.body}}>{configJSON.helpCenter}</FooterHeadingText>
                     <Box style={{ flexDirection: "row" }}>
                       <Box style={{ display: "flex", width: '60%', justifyContent: "space-between" }}>
                         <Box style={{ flexDirection: "row" }}>
                         <FooterSubHeadingText style={{fontFamily: selectedFonts.body}} >About Us</FooterSubHeadingText>
                         <FooterSubHeadingText style={{fontFamily: selectedFonts.body}} >FAQs</FooterSubHeadingText>
                      </Box>
                        <Box style={{ flexDirection: "row" }}>
                          <FooterSubHeadingText style={{fontFamily: selectedFonts.body}} >Privacy Policy</FooterSubHeadingText>
                          <FooterSubHeadingText style={{fontFamily: selectedFonts.body}} >Terms of Service</FooterSubHeadingText>
                       </Box>
                        </Box>
                      </Box>

                      {this.props.statesData.updateData.footer?.is_contact &&
                        <Box>
                          <FooterHeadingText style={{fontFamily: selectedFonts.body}}>{configJSON.contactUs}</FooterHeadingText>
                          <FooterContactUs>
                            <FooterSubHeadingText style={{fontFamily: selectedFonts.body}}>{configJSON.sendMessage}</FooterSubHeadingText>
                            <FooterSubHeadingText style={{fontFamily: selectedFonts.body}}>{configJSON.contact}</FooterSubHeadingText>
                          </FooterContactUs>
                        </Box>
                      }

                      {
                        this.props.statesData.updateData?.footer?.is_app_link &&
                        <>
                          {this.props.statesData.updateData?.footer?.is_app_link && (this.props.statesData.updateData?.footer?.playstore_url.toString()?.length > 0 || this.props.statesData.updateData?.footer?.appstore_url.toString()?.length > 0) &&
                            <FooterHeadingText style={{fontFamily: selectedFonts.body}}>{configJSON.download}</FooterHeadingText>
                          }
                          <MainBox>
                            {this.props.statesData.updateData?.footer?.playstore_url.toString()?.length > 0 && this.props.statesData.updateData?.footer?.is_app_link &&
                              <FooterStoreIconBox href={this.props.statesData.updateData?.footer?.playstore_url.toString()} target="_blank">
                                <FooterStoreIcon src={GooglePlayIcon} />
                              </FooterStoreIconBox>
                            }
                            {this.props.statesData.updateData.footer?.appstore_url.toString()?.length > 0 && this.props.statesData.updateData?.footer?.is_app_link &&
                              <FooterStoreIconBox href={this.props.statesData.updateData?.footer?.appstore_url.toString()} target="_blank">
                                <FooterStoreIcon src={AppStore} />
                              </FooterStoreIconBox>
                            }
                          </MainBox>
                        </>
                      }

                    {this.props.statesData.updateData.footer?.is_social_media && (this.props.statesData.updateData.footer?.facebook_url.toString()?.length > 0 || this.props.statesData.updateData.footer?.instagram_url.toString()?.length > 0 || this.props.statesData.updateData.footer.x_url.toString().length > 0 || this.props.statesData.updateData.footer?.youtube_url.toString()?.length > 0) &&
                                        <Box>
                                            <FooterHeadingText style={{fontFamily: selectedFonts.body}}>{configJSON.followUs}</FooterHeadingText>
                                            <FooterFollowContainer>
                                            {(this.props.statesData.updateData.footer.facebook_url && this.props.statesData.updateData.footer?.is_social_media) &&
                                                    <FooterIconBox>
                                                        <FooterSocialLinkBox href={this.props.statesData.updateData.footer.facebook_url.toString()} target="_blank">
                                                            <FooterSocialLinkIcon style={{ width:"5.18px", height:"9.88px" }} src={facebookLogo} />
                                                        </FooterSocialLinkBox>
                                                    </FooterIconBox>
                                            }

                                            {this.props.statesData.updateData.footer.instagram_url && this.props.statesData.updateData.footer?.is_social_media &&
                                                    <FooterIconBox>
                                                        <FooterSocialLinkBox href={this.props.statesData.updateData.footer.instagram_url.toString()} target="_blank">
                                                            <FooterSocialLinkIcon style={{ width:"9.87px", height:"9.87px" }} src={instagramLogo}  />
                                                        </FooterSocialLinkBox>
                                                    </FooterIconBox>
                                            }

                                            {(this.props.statesData.updateData.footer.x_url && this.props.statesData.updateData.footer?.is_social_media) &&
                                                    <FooterIconBox>
                                                        <FooterSocialLinkIcon style={{ width:"10.6px", height:"8.18px" }} src={twitterLogo} />
                                                    </FooterIconBox>
                                            }

                                            {this.props.statesData.updateData.footer.youtube_url && this.props.statesData.updateData.footer?.is_social_media &&
                                                    <FooterIconBox>
                                                        <FooterSocialLinkBox href={this.props.statesData.updateData.footer.youtube_url.toString()} target="_blank">
                                                            <FooterSocialLinkIcon style={{width:"9.87px", height:"7.9px" }} src={youtubLogo} />
                                                        </FooterSocialLinkBox>
                                                    </FooterIconBox>
                                            }
                                            </FooterFollowContainer>

                                        </Box>
                     }

                  </FooterSectionBox>

                  <FooterCopyRightBox>
                    {this.props.statesData.updateData.footer?.copyright &&
                      <FooterCopyRightText component={"span"} style={{fontFamily: selectedFonts.body}}>
                        {this.props.statesData.updateData.footer?.copyright}
                      </FooterCopyRightText>
                    }
                   
                  </FooterCopyRightBox>
                </MobilePreview>
              )}
            </ProductPreviewBox>}
      </>
      // Customizable Area End
    );

  }
};


// Customizable Area Start
export default withDialog(withLoader(withHeaderBar(ProductPreviewScreen)));
export { ProductPreviewScreen }
// Customizable Area End

// Customizable Area Start

const webStyle = {
  bodyContainer: {
    background: "linear-gradient(180deg, rgba(34, 34, 34, 1) 50%, rgba(34, 34, 34, 1) 50%, rgba(255,255,255,1) 50%)",
    padding: "20px 0"
  },
  carouselContainer: {
    position: "relative" as "relative",
    overflow: "hidden",
    height: "200px !important",
    width: "160px !important",
    borderRadius: "18px 12px 18px 12px !important",
  },
  textContainer: {
    position: "relative" as "relative",
    width: "140px",
    height: "100%",
    borderRadius: "18px 12px 18px 12px",
    objectFit: "cover" as "cover"
  },
  overlay: {
    position: "absolute" as "absolute",
    bottom: "10px",
    color: "#ffffff",
    fontFamily: "var(--website-builder-body-font)",
    marginLeft: "10px"
  },
  staffName: {
    fontSize: "12px",
    fontWeight: 700,
    letterSpacing: "1px"
  },
  designation: {
    fontSize: "10px",
    fontWeight: 400,
    letterSpacing: "1px"
  },
  headerTextField: {
    color: "#ffffff",
    fontFamily: "var(--website-builder-body-font)",
    fontSize: "22px",
    fontWeight: 700,
    margin: "20px 0",
    textAlign: "center" as "center"
  },
  viewAllBtn: {
    marginTop: "30px",
    fontSize: "16px",
    lineHeight: "44px",
    fontWeight: 700,
    letterSpacing: "-1px",
    cursor: "pointer",
    color: "var(--website-builder-primary-color)",
    fontFamily: "var(--website-builder-body-font)",
    textAlign: "center" as "center",
    zIndex: 1,
    textDecoration: "none",
    backgroundColor: "#5128881A",
    padding: "2px 28px",
    borderRadius: "8px"
  },
  viewAllBtn1: {
    marginBottom: "20px",
    fontSize: "16px",
    lineHeight: "44px",
    fontWeight: 700,
    letterSpacing: "-1px",
    cursor: "pointer",
    color: "var(--website-builder-primary-color)",
    fontFamily: "var(--website-builder-body-font)",
    textAlign: "center" as "center",
    zIndex: 1,
    textDecoration: "none",
    backgroundColor: primaryColor + '1A',
    padding: "2px 28px",
    borderRadius: "8px"
  },

  listItem:{
      width: "23%",
      display: "flex",
      flexDirection: "column" as "column",
      minWidth: "270px",
      padding: 0,
      alignItems: "start" as "start",
      textAlign: "start" as "start"
  },

  testimonialStar: {
    fontSize: "24px"
  },

  testimonialRated: {
    color: "#FFC400"
  },

  testimonialUnrated: {
    color: "#C4C4C4"
  },
   
  banner :{
    width: "321px",
    height: "319px",
    objectFit: "fill" as "fill",
    borderRadius: "50px 0px 50px 0px"
  },
  image:{
    borderRadius: "12px",
    objectFit: "cover" as "cover"
  }
};

const SliderContainer = styled(Box)({
  "& .slick-track": {
    margin: "0 80px",
    display: "flex",
  },
  "& .slick-next": {
    display: "none !important"
  },
  "& .slick-prev": {
    display: "none !important"
  },
  "& .slick-slide": {
    width: "360px !important",
    margin: "0 10px",
    height: "180px !important",
    "& > *:nth-child(1)": {
      height: "100%",
      "& > *:nth-child(1)": {
        height: "100%"
      }
    }
  }
})

const ProductPreviewBox = styled(Box)({
  width: "100%",
  backgroundColor: "#f6f7f9",
  display: "flex",
  justifyContent: "center",
  boxSizing: "border-box",
  padding: "25px",
  height:"calc(100vh - 242px)",
  overflow:"auto"
});

const MobilePreview = styled(Box)({
  width: "375px",
  backgroundColor: "#ffffff",
  boxShadow:
    "0px 8px 32px 0px rgba(0, 0, 0, 0.06), 0px 4px 8px 0px rgba(0, 0, 0, 0.03)",
  height: "max-content"
});

const MobileMenuScreen = styled(Box)({
  width: "375px",
  height: "625px",
  backgroundColor: "#ffffff",
  boxShadow:
    "0px 8px 32px 0px rgba(0, 0, 0, 0.06), 0px 4px 8px 0px rgba(0, 0, 0, 0.03)"
});

const NavigationSettingsHeader = styled(Box)({
  width: "375px",
  height: "58px",
  display: "flex",
  padding: "16px 24px",
  justifyContent: "space-between",
  alignItems: "center",
  borderBottom: "1px solid #E2E8F0"
});

const MobilePreviewHeader = styled(Box)({
  width: "100%",
  height: "48px",
  padding: "10px 20px",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  boxSizing: "border-box"
});

const MobileMenuPreviewHeader = styled(Box)({
  width: "100%",
  padding: "10px",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  boxSizing: "border-box",
  borderBottom: "1px solid #E2E8F0"
});

const AddLogoBox = styled(Box)({
  width: "50px",
  height: "34px",
  borderRadius: "6px",
  border: "2px dashed #adafbd",
  padding: "5px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  boxSizing: "border-box",
  rowGap: "2px"
});

const AddLogoText = styled(Typography)({
  fontSize: "7px",
  fontWeight: 500,
  color: "#676B7E"
});

const AddLogoPlusIcon = styled("img")({
  width: "9.2px",
  height: "7.5px",
  padding: "0.31px 0.25px",
  objectFit: "contain",
  color: "#67687E"
});

const MainBox = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  width: "80%",
  flexDirection: "row"
})

const HeaderMenuIcon = styled("img")({
  width: "18px",
  height: "12px",
  objectFit: "contain"
});

const HeaderIconAndNameHolder = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  color: 'rgba(0, 0, 0, 1)',
  paddingLeft: "15px",
  width: "100%",
  justifyContent: "center"
});

const HeaderSearchIconOne = styled(Box)({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  gap: "16px"
});

const HeaderSearchIcon = styled("img")({
  width: "17.5px",
  height: "17.5px",
  objectFit: "contain",
});

const HeaderCloseIcon = styled("img")({
  width: "24px",
  height: "24px",
  objectFit: "contain",
});

const MobileMenuVectorIcon = styled("img")({
  width: "7px",
  height: "11.2px",
  objectFit: "contain",
});

const AddBannerSection = styled(Box)({
  width: "375px",
  height: "407px",
  padding: "30px",
  boxSizing: "border-box",
  backgroundColor: "#D9D9D9",
  position: "relative"
});

const InnerAddBannerBox = styled(Box)({
  borderRadius: "10px",
  border: "2px dashed #C0C3CE",
  height: "100%",
  width: "100%",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center"
});

const AddIconBox = styled(Box)({
  display: "flex",
  width: "16px",
  height: "16px",
  padding: "0.5px",
  justifyContent: "center",
  alignItems: "center"
});

const AddBannerText = styled(Typography)({
  fontSize: "20px",
  fontWeight: 500,
  letterSpacing: "-0.4px",
  color: "#676B7E"
});

const AddBannerSizeText = styled(Typography)({
  fontSize: "10px",
  fontWeight: 400,
  color: "#676B7E"
});

const AddBannerPlusIcon = styled("img")({
  height: "15px",
  width: "15px"
});

const CircleSectionBox = styled(Box)({
  padding: "25px 0",
  position: "relative"
});

const CircleSectionContentBox = styled(Box)({
  display: "flex",
  columnGap: "10px",
  overflowX: "scroll",
  margin: "0 10px",
  overflow : "hidden",
  "&::-webkit-scrollbar": {
    width: " 0.5em",
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "FFF"
  },
  "&::-webkit-scrollbar-track": {
    backgroundColor: "FFF"
  }

});

const CircleContentBox = styled(Box)({
  height: "163px",
  width: "163px",
  borderRadius: "100%",
  backgroundColor: "#D9D9D9",
  flex: "0 0 auto"
});

const ProductPriceBox = styled(Box)({
  display: "flex",
  gap: "5px"
});

const ProductItemData = styled(Box)({
  display: "flex",
  flexDirection: "column",
  rowGap: "5px",
  backgroundColor: "#ffffff",
  borderRadius: "0 0 8px 8px",
  padding: "10px",
  width: "160px",
})

const ProductName = styled(Typography)({
  color: "var(--secondary-color)",
  fontFamily: "var(--header-text-font) !important",
  fontSize: "10.07px",
  fontStyle: "normal",
  fontWeight: 700,
  lineHeight: "10.863px"
})

const CategoryName = styled(Typography)({
  color: "var(--secondary-color)",
  fontFamily: "var(--body-text-font) !important",
  fontSize: "6.912px",
  fontStyle: "normal",
  fontWeight: 700,
  lineHeight: "10.863px"
})

const ProductPriceText = styled(Typography)({
  color: "var(--secondary-color)",
  fontFamily: "var(--body-text-font)",
  fontSize: "10.07px",
  fontStyle: "normal",
  fontWeight: 500,
  lineHeight: "10.863px"
})

const CrossPriceText = styled(Typography)({
  color: "var(--secondary-color)",
  opacity: 0.4,
  fontFamily: "var(--body-text-font)",
  fontSize: "10.07px",
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "10.863px",
  textDecoration: "line-through"
})

const DiscountPriceText = styled(Typography)({
  color: "var(--primary-color)",
  fontFamily: "var(--body-text-font)",
  fontSize: "10.07px",
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "10.863px",
})

const DiscountPriceBox = styled(Box)({
  display: "flex",
  columnGap: "5px"
})

const ServiceItemBox = styled(Box)({
  display: "flex",
  flexDirection: "column",
  marginTop: "10px",
  alignItems: "center",
  width: "160px",
  overflow: "hidden"
})

const ServiceItemDummyBox = styled(Box)({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  width: "160px",
  overflow: "hidden",
  backgroundColor: '#D9D9D9',
  height: '200px'
})

const ProductItemImage = styled(Box)({
  width: "173px",
  height: "198px",
  borderRadius: "5px",
  backgroundColor: "#D9D9D9"
})

const SectionHeading = styled(Typography)({
  fontSize: "22px",
  fontWeight: 700,
  lineHeight: "30px",
  textAlign: "center",
  margin: "20px 0px",
  color: "var(--website-builder-secondary-color)",
  fontFamily: "var(--website-builder-header-font)",
});

const HeadingContainer = styled(Box)({
  padding: "10px 10px 10px"
})

const CustomDivider = styled('hr')({
  height: "3px",
  backgroundColor: "#3FC1CB",
  width: "20%",
  margin: "0 0 10px 10px",
  opacity: 1,
  border: 0,
  }
)

const FooterSectionBox = styled(Box)({
  width: "100%",
  padding: "20px",
  backgroundColor: "#222222",
  boxSizing: "border-box",
});

const FooterHeadingText = styled(Typography)({
  color: "#F1F5F9",
  fontSize: "18px",
  fontWeight: 600,
  lineHeight: "24px",
  margin: "15px 0",
  fontFamily: "var(--website-builder-body-font)",
});

const FooterSubHeadingText = styled(Typography)({
  color: "#E2E8F0",
  fontSize: "14px",
  fontWeight: 400,
  lineHeight: "24px",
  margin: "5px 0",
  fontFamily: "var(--website-builder-body-font)"
});

const FooterSocialLinkBox = styled("a")({
  display: "flex",
  // alignItems: "center",
  // columnGap: "10px",
  textDecoration: "none"
});

const FooterContactUs = styled("a")({
  display: "flex",
  width: "80%",
  justifyContent: "space-between",
  textDecoration: "none"
});

const FooterSocialLinkIcon = styled("img")({
  width: "17px",
  height: "17px",
  objectFit: "contain",
});

const FooterStoreIconBox = styled("a")({
  width: "113px",
  height: "30px",
  margin: "10px 0",
  borderRadius: "5px",
  textDecoration: "none"
});

const FooterStoreIcon = styled("img")({
  width: "121px",
  height: "37px"
});
const FooterCopyRightBox = styled(Box)({
  width: "100%",
  backgroundColor: "#222222",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  padding: "10px 0",
});

const FooterCopyRightText = styled(Box)({
  color: "#ffffff",
  textAlign: "center",
  fontSize: "12px",
  fontWeight: 400,
  lineHeight: "24px",
  fontFamily: "var(--website-builder-body-font)",
});

const LocationTitleBox = styled(Typography)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
  margin: "20px 0 40px 0"
}));

const LocationTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 700,
  lineHeight: "33px",
  paddingBottom: theme.spacing(1),
  fontSize: "22px",
  fontFamily: "var(--website-builder-header-font)",
  color: "var(--website-builder-secondary-color)"
}));

const LocationWrapper = styled(Box)(({ theme }) => ({
  width: "100%",
  position: "relative"
}));

const LocationCard = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  backgroundColor: "#000000B2",
  padding: "15px 10px",
  width: "215px",
  position : "absolute",
  bottom: "5px",
  marginLeft: "5px",
  borderRadius: "5.9px"
}));

const LocationMapBox = styled(Box)(({ theme }) => ({
  width:"100%"
}));

const AddressTitle = styled(Typography)(({ theme }) => ({
  fontSize: "12px",
  fontWeight: 400,
  lineHeight: "22px",
  color: "#FFFFFF",
  fontFamily: "var(--website-builder-body-font)",
}));

const AddressBody = styled(Typography)(({ theme }) => ({
  fontSize: "12px",
  lineHeight: "18px",
  color: "#FFFFFF",
  fontFamily: "var(--website-builder-body-font)",
}));

const OpeneingBox = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
}));

const LocationContainer = styled(Box)(({ theme }) => ({
  padding: "20px"
}));

const Devider = styled(Divider)(({ theme }) => ({
  border: "2px dashed #D9D9D9A6",
  margin: "10px 0px"
}));

const FooterFollowContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  gap: "10px"
}));

const BannerContainer = styled(Box)(({ theme }) => ({
  height:"556px",
  width: "100%",
  backgroundColor: "#222",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  padding: "60px 30px",
  flexDirection: "column"
}));

const BannerText = styled(Typography)(({ theme }) => ({
  fontSize: "30px",
  fontWeight: 700,
  lineHeight: "68px",
  width: "100%",
  color: "#fff",
  wordWrap: "break-word",
  textAlign: "center"
}));

const BannerImageContainer = styled(Box)(({ theme }) => ({
  width: "256x",
  minWidth: "256px",
  height: "254px"
}));

const MainTestimonialBox = styled(Box)({
  background: "#F5F5F5",
  padding: "20px",
  display: "flex",
  flexDirection: "column",
  gap: "20px",
  "& .sectionName":{
    fontFamily: "var(--website-builder-header-font)",
    color: "var(--website-builder-secondary-color)",
    fontSize: "22px",
    fontWeight: 700,
    lineHeight: "44px"
  },
  "& .MuiTypography-h6": {
    fontSize: "22px",
    fontWeight: 700,
    lineHeight: "44px",
    fontFamily: "Lato",
    color: "#222222"
  },
  "& .cardBox": {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  "& .card": {
    backgroundColor: "white",
    height: "219px",
    maxWidth: "350px",
    borderRadius: "24px 0px 24px 0px",
    padding: "15px",
    display: "flex",
    flexDirection: "column",
    gap: "10px",
    border: "none"
  },
  "& .cardHead": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-end",
    height: "30%",
  },
  "& .testominialName": {
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "26px",
    fontFamily: "var(--website-builder-body-font)",
    color: "var(--website-builder-secondary-color)"
  },
  "& .cardContent": {
    textAlign: "justify",
    height: "70%"
  },
  "& .testiminialtext":{
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "21px",
    fontFamily: "var(--website-builder-body-font)",
    color: "var(--website-builder-secondary-dark-accent-color)"
  },
  "& .quoteIcon": {
    color: "#3C3E49",
    width: "36px",
    height: "32px"
  },
  "& .btnBox": {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "10px"
  },
  "& .iconBtn": {
    width: "36px",
    height: "36px",
    padding: "10px",
    boxSizing: "border-box",
    color: "#000000",
    "&:hover": {
      boxShadow: "0px 4px 16px 0px #00000026"
    }
  },
});

const FooterIconBox = styled(Box)({
   border: "1px solid #ffffff",
   borderRadius: "4px",
   width: "20.74px",
   height: "21.73px",
   display:"flex",
   justifyContent: "center",
   alignItems: "center"
});

const MainServiceBox = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
  overflowX: "auto",
  "& .bodySection":{
      display: "flex",
      paddingTop: "10px",
      justifyContent: "flex-start",
      width: "100%",
      flexDirection: "column"
  },
  "& .serviceCard":{
      overflow: "visible",
      "& .MuiCard-root": {
          borderRadius: "0px",
          overflow: "visible"
      }
  },
  "& .cardContent":{
      display: "flex",
      flexDirection: "column",
      gap: "10px",
      padding: "7px 14px",
      paddingBottom:"0",
      "& .MuiTypography-body1": {
          fontSize: "20px",
          fontWeight: 500,
          lineHeight: "30px",
          fontFamily: "var(--website-builder-header-font)",
          color: "var(--website-builder-primary-color)",
      }
  },
  "& .cardDesc":{
      fontSize: "16px",
      fontWeight: 500,
      lineHeight: "24px",
      fontFamily: "var(--website-builder-body-font)",
      color: "var(--website-builder-secondary-color)",
  },
  "& .priceBox": {
      "& .MuiTypography-body2": {
        fontSize: "24px",
        fontWeight: 400,
        lineHeight: "36px",
        fontFamily: "Rubik",
        color: "#000000",
      },
      "& .discountedPrice": {
        fontSize: "16px",
        fontWeight: 400,
        lineHeight: "24px",
        color: "var(--website-builder-secondary-color)",
        fontFamily: "var(--website-builder-body-font)",
      },
      "& .price": {   
        color: "var(--website-builder-secondary-color)",
        fontFamily: "var(--website-builder-body-font)",
      }
    },
    "& .imageBox": {
      width: "100%",
      height: "410px",
      position: "relative",
      [theme.breakpoints.down("xs")]: {
        maxWidth: "340px",
        height: "260px",
        width: "100%"
      }
    },
    "& .timeContainer": {
      top: "10px",
      right: "10px",
      padding: "8px",
      borderRadius: "8px",
      position: "absolute",
      backgroundColor: "#E6FEFF",
      display: "flex",
      flexDirection: "column",
      alignItems: "center"
    },
    "& .duration": {
      fontSize: "14px",
      fontWeight: 500,
      lineHeight: "24px",
      color: "#1CC9D1",
      [theme.breakpoints.down("xs")]: {
        fontSize: "14px",
        fontWeight: 500,
        lineHeight: "21px",
      }
    },
    "& .minutes": {
      fontSize: "12px",
      fontWeight: 400,
      lineHeight: "24px",
      color: "#1CC9D1",
      [theme.breakpoints.down("xs")]: {
        fontSize: "12px",
        fontWeight: 400,
        lineHeight: "18px",
      }
    },
    "& .cardFooterConatiner": {
      display: "flex",
      justifyContent: "space-between",
      marginTop: "10px",
      alignItems: "center"
    },
    "& .bookNowButton": {
      textAlign: "center",
      backgroundColor: "var(--website-builder-primary-color)",
      fontFamily: "var(--website-builder-body-font)",
      borderRadius: "4px",
      cursor: "pointer",
      fontSize: "16px",
      fontWeight: 500,
      lineHeight: "11.45px",
      padding: "12px 16px",
      height: "fit-content",
      whiteSpace: "nowrap",
      color: "#ffffff",
    },
    "@media screen and (max-width:599px)": {
      padding: "20px"
    },    
    "& .serviceBrief": {
      fontSize: "18px",
      lineHeight: "27px",
      color: "#1E293B",
    },
    
}));

const ManageBookingBtn = styled(Box)({
  display: "flex",
  justifyContent: "center",
  flex: 1,
  backgroundColor: "var(--website-builder-primary-color)",
  fontSize: "14px",
  fontWeight: 700,
  padding: "8px 24px",
  lineHeight: "20px",
  borderRadius: "8px",
  textDecoration: "none",
  whiteSpace: 'nowrap',
  margin: "20px auto",
  width: "90%",
  color: "#ffffff"
});

const HeaderNavigation = styled(Box)({
  fontSize: "16px",
  fontWeight: 600,
  color: "var(--website-builder-secondary-color)",
  fontFamily: "var(--website-builder-body-font)"
});

const ViewAllBtn = styled(Box)(({ theme }) => ({
  marginTop: "15px",
  marginBottom: "10px",
  fontSize: "15px",
  lineHeight: "37px",
  fontWeight: 700,
  cursor: "pointer",
  color: "var(--website-builder-primary-color)",
  fontFamily: "var(--website-builder-body-font)",
  textAlign: "center" as "center",
  zIndex: 1,
  textDecoration: "none",
  backgroundColor: "var(--website-builder-primary-light-accent-color)",
  padding: "2px 28px",
  borderRadius: "8px"
}));
// Customizable Area End